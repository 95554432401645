import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { API_BASE, LRS_ENDPOINT } from "../../../constants/global";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import { useState, useEffect } from "react";
import { event } from "jquery";
import CustomPagination from "../../../entity/CustomPagination";
import VerifiedBadge from "../../../components/VerifiedBadge";
import { Modal } from "react-bootstrap";
import ReactSpeedometer from "react-d3-speedometer";
import ProgressBar from 'react-bootstrap/ProgressBar';

const VacancyLeaderboard = ({ ekCourseTitle, ekCourseSlug }) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);
    const { vacancy_id } = useParams();
    const _token = localStorage.getItem('lmstoken');
    const [employee, setEmployee] = useState([]);
    const [vacancy_info, setVacancyInfo] = useState('');
    const [report_file_url, setReportFileUrl] = useState('');
    const [total_count, setTotalCount] = useState(0);
    const [material_ids, setMaterialids] = useState('');
    const [sharingReport, setSharingReport] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue, setError, setFocus } = useForm();
    const [emails, setEmails] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [ref_vacancy, setRefVacancy] = useState(0);
    const [NoteModalShow, setNoteModalShow] = useState(false);
    const [noteContent, setNoteContent] = useState('');
    const [refresh2, setRefresh2] = useState(0);
    const [_course_comp_avg, setCourseCompAvg] = useState(0);
    const [proctoringData, setProctoringData] = useState({});
    const [proctoringSummary, setProctoringSummary] = useState([]);


    const [CopyCount2, setCopyCount2] = useState(0);
    const [PasteCount2, setPasteCount2] = useState(0);
    const [NewTab2, setNewTab2] = useState(0);
    const [Screenshot2, setScreenshot2] = useState(0);
    const [Console2, setConsole2] = useState(0);
    const [Escape2, setEscape2] = useState(0);
    const [Inactivity2, setInactivity2] = useState(0);
    const needtoRefresh = () => {
        setRefresh2(!refresh2);
    };

    const clearnotecontent = () => {
        setNoteContent('');
    }

    useEffect(() => {
        fetchVacancy(currentPage);
    }, [location, reload, refresh2]);

    const toggleLeftMenu = async () => {
        try {
            let sidebar = document.querySelector(".sidebar");
            //sidebar.classList.toggle("close");
            sidebar.classList.add("close");
        } catch (e) { }
    }

    const onSubmit = async (data) => {
        if (emails.length >= 30) {
            setError("email", { type: "focus", message: 'Maximum 30 emails can be sent at a time.' }, { shouldFocus: false });
            return false;
        }
        setProcessing(true);
        var _email_string = data.email;
        var _remove_all_spaces = _email_string.replace(/\s\s+/g, ' ');
        var _convert_space_in_coma = _remove_all_spaces.split(/[ ,]+/).join(',');
        var iEmail = _convert_space_in_coma.trim();
        var comEmails = iEmail.split(",");
        if (typeof comEmails !== 'undefined' && comEmails.length > 1) {
            var duplicate = false;
            var checkEmails = [];
            for (let index = 0; index < comEmails.length; index++) {
                const sepEmail = comEmails[index].trim();
                if (emails.indexOf(sepEmail) === -1) {
                    checkEmails.push(sepEmail);
                } else {
                    duplicate = true;
                }
            }
            const callAsync = async () => {
                const newList = [...emails, ...checkEmails];
                setEmails(newList.splice(30));
                setEmails(newList);
            }
            callAsync();
            if (duplicate) {
                Toaster.info('Some of the email(s) are duplicate and not added to the list.');
            }
            setValue('email', '');
            setFocus('email');
            setProcessing(false);
        } else {
            if (emails.indexOf(iEmail) !== -1) {
                setError("email", { type: "focus", message: 'This email already in the list.' }, { shouldFocus: true });
                setProcessing(false);
                return false;
            }
            setEmails([...emails, iEmail]);
            setValue('email', '');
            setFocus('email');
            setProcessing(false);
        }
    }
    const removeItem = async (e, item) => {
        const newEmails = [];
        emails.forEach((email, index) => {
            if (item !== email) {
                newEmails.push(email);
            }
        });
        setEmails(newEmails);
    }
    const processShareReport = async () => {
        var formData = new FormData();
        for (var i = 0; i < emails.length; i++) {
            formData.append('members[]', emails[i]);
        }
        //alert(emails.toString());
        var _email_string = emails.toString();
        exportResults(_email_string);
    }


    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }
    const s2t = function (t) {
        return parseInt(t / 86400) + 'd ' + (new Date(t % 86400 * 1000)).toUTCString().replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, "$1h $2m $3s");
    }


    function sortByKey(array, key1, key2, sortOrder = 'asc') {
        const sortedArray = array.slice().sort((a, b) => {
            if (a[key1] === 0) {
                // If spent_time is 0, move to the end of the array
                return 1;
            } else if (b[key1] === 0) {
                // If spent_time is 0, move to the end of the array
                return -1;
            } else if (a[key1] < b[key1]) {
                return sortOrder === 'asc' ? -1 : 1;
            } else if (a[key1] > b[key1]) {
                return sortOrder === 'asc' ? 1 : -1;
            } else if (a['score'] > 60 && b['score'] > 60) {
                // If spent_time is equal, compare scores if both scores are greater than 60
                if (a[key2] < b[key2]) {
                    return sortOrder === 'asc' ? -1 : 1;
                } else if (a[key2] > b[key2]) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
            }
            return 0;
        });
        return sortedArray;
    }


    function sortByScore(array, sortKey, sortOrder = 'asc') {
        if (sortOrder !== 'asc' && sortOrder !== 'desc') {
            throw new Error("Invalid sortOrder. Use 'asc' or 'desc'.");
        }
        return array.sort((a, b) => {
            const valueA = a[sortKey];
            const valueB = b[sortKey];
            if (valueA == "0 / 0" || valueA == undefined) return 1;
            if (valueB == "0 / 0" || valueB == undefined) return -1;

            if (sortOrder === 'asc') {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        });
    }

    function sortByMultipleKeys(array, sortKeys, sortOrders) {
        return array.sort((a, b) => {
            for (let i = 0; i < sortKeys.length; i++) {
                const key = sortKeys[i];
                const sortOrder = sortOrders[i] || 'asc';

                const valueA = a[key];
                const valueB = b[key];

                if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
                if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }


    const fetchVacancy = async (page = 0, search = "") => {
        setEmployee([]);
        SwalBox.fire({
            title: "Processing...",
            html: "Please wait...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
            SwalBox.showLoading();
            },
        });
        
        let _course_comp_avg = 0;
                const response = await fetch(`${API_BASE}vacancy/detail/${vacancy_id}?page=${page}&search=${search}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + _token
                    },
                });
                const data = await response.json();
                if (data.status) {
                    setVacancyInfo(data.data.vacancy);
                    setRefVacancy(data.data.vacancy.ref_id);
                    let _existing_course_rows = data.data.vacancy;
                    fetchMaterials(data.data.vacancy.course_id);
        
                    let _updated_leaderboard_rows = [];
                    let _updated_sorted_leaderboard_rows = [];
        
                    let _avg_total_time = 0;
                    let _avg_total_emp  = 0;
        
                    let _total_time = 0;
                    if (data.data.student_count !== 0) {
                        let _existing_leaderboard_rows = data.data.students;
                        if (_existing_leaderboard_rows.length > 0) {
                            for (let [i, _existing_leaderboard_row] of _existing_leaderboard_rows.entries()) {
                                
                                var _req_slug = _existing_course_rows.course_id;
                                var _req_agent = _existing_leaderboard_row.email;
                                var _rep_1_request_json = { 'slug': _req_slug, 'agent': _req_agent };
                                const response_n = await fetch(`${LRS_ENDPOINT}statements/get-course-duration?slug=` + _req_slug + '&agent=' + _req_agent, {
                                    method: 'POST',
                                    body: JSON.stringify(_rep_1_request_json)
                                });
                                let data_n = await response_n.json();
                                let _duration = data_n.duration;
        
                                if (_duration != undefined && typeof (_duration) != 'undefined') {
                                    _existing_leaderboard_row.spent_time_2 = parseInt(_duration);
                                } else {
                                    _existing_leaderboard_row.spent_time_2 = 0;
                                }
                                if (_existing_leaderboard_row.completed != null) {
                                    if (_duration != undefined && typeof (_duration) != 'undefined') {
                                        _existing_leaderboard_row.duration.total_hours = s2t(_duration);
                                        _existing_leaderboard_row.spent_time = parseInt(_duration);
                                        _total_time += parseInt(_duration);
                                    } else {
                                        _existing_leaderboard_row.duration.total_hours = '0 Hr';
                                        _existing_leaderboard_row.spent_time = 0;
                                    }
                                    if (typeof (_existing_leaderboard_row.quiz.right_count) != 'undefined') {
                                        _existing_leaderboard_row.score = parseFloat(parseInt(_existing_leaderboard_row.quiz.right_count) / parseInt(_existing_leaderboard_row.quiz.total_count) * 100);
                                    } else {
                                        _existing_leaderboard_row.score = 0;
                                    }
                                    _avg_total_time += _existing_leaderboard_row.spent_time;
                                    _avg_total_emp++;
                                } else {
                                    _existing_leaderboard_row.spent_time = parseInt(0);
                                }
                                _updated_leaderboard_rows.push(_existing_leaderboard_row);
                            }
        
                            //let sortedByTime = sortByKey(_existing_leaderboard_rows, 'spent_time', 'desc');
                            //let sortedByTime = sortByKey(_updated_leaderboard_rows, 'spent_time', 'desc');
                            //let sortByScorec = sortByScore(_updated_leaderboard_rows, 'score', 'desc');
                            let sortByScorec = sortByMultipleKeys(_updated_leaderboard_rows, ['score', 'spent_time'], ['desc', 'desc']);
                            setEmployee([]);
                            setEmployee(sortByScorec);
                            setTotalCount(data.data.student_count ? data.data.student_count : 0);
        _course_comp_avg = parseInt(_avg_total_time / _avg_total_emp);
                            setCourseCompAvg(_course_comp_avg); 
                            SwalBox.close();
                        }
                    } else {
                        setEmployee(data.data.students ? data.data.students : '');
                        setTotalCount(data.data.student_count ? data.data.student_count : 0);
                        SwalBox.close();
                    }
                } else {
                    setEmployee([]);
                    SwalBox.close();
                }
            }
    const updateStatus = async (old_status, status, student_id, sindex) => {
        SwalBox.close();
        SwalBox.fire({
            title: "Add a Note",
            input: 'textarea',
            inputPlaceholder: 'Before you make any changes, it is required to add a note for the reference...',
            inputAttributes: {
                'aria-label': 'Before you make any changes, it is required to add a note for the reference...'
            },
footer:'<span class="ek-red-col-text"><i class="fa fa-info-circle" aria-hidden="true"></i> Remember, it is mandatory to include a note. The hiring status you have selected will not be updated unless you add a note.</span>',
            confirmButtonColor: '#30314c',
            cancelButtonColor: '#ef403d',
            confirmButtonText: 'Submit',
            showCancelButton: true,
            allowOutsideClick: false,
            inputValidator: function (value) {
                if (value === '') {
                    return 'You need to write something!'
                } else {
                    proceedUpdateStatus(status, student_id, sindex, value);
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                //proceedUpdateStatus(status, student_id , sindex);
            } else if (result.isDenied) {
                document.getElementById('empva_' + student_id).value = old_status;
            } else if (result.isDismissed) {
                if (old_status == null) {
                    var selectBox = document.getElementById('empva_' + student_id);
                    selectBox.selectedIndex = 0;
                } else {
                    document.getElementById('empva_' + student_id).value = old_status;
                    var selectBox = document.getElementById('empva_' + student_id);
if (old_status == 'Hired') {
                        selectBox.selectedIndex = 1;
                    } else if (old_status == 'Not Contacted') {
                        selectBox.selectedIndex = 2;
                    } else if (old_status == 'Rejected') {
                        selectBox.selectedIndex = 3;
                    } else {
                        selectBox.selectedIndex = 0;
                    }
                }





                //var selectBox = document.getElementById('empva_'+student_id);
                //selectBox.selectedIndex = -1;

                /*
                if(old_status = null){
                    var selectBox = document.getElementById('empva_'+student_id);
                    selectBox.selectedIndex = -1; // Deselects all options
                }else{
                    document.getElementById('empva_'+student_id).value = old_status;
                }
                */



            } else {
                document.getElementById('empva_' + student_id).value = old_status;
            }
        })
    }

    const proceedUpdateStatus = async (status, student_id, sindex, unote) => {
        let _user_note = ''
        if (unote != '' && unote != null) {
            _user_note = unote;
        } else {
            _user_note = null;
        }
        const formData = new FormData();
        if (sindex < 3) {
            formData.append('is_recommended', 'Recommended');
            formData.append('system_note', 'This candidate has spent more than average time & having good quiz score.');
            formData.append('user_note', _user_note);
        } else {
            formData.append('is_recommended', 'Not Recommended');
            formData.append('system_note', 'This candidate has completed the course in less than the average time.');
            formData.append('user_note', _user_note);
        }
        formData.append('student_id', student_id);
        formData.append('status', status);
        const response = await fetch(`${API_BASE}update-student-vacancy-status/${vacancy_id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            SwalBox.close();
            Toaster.success(data.message);
            fetchVacancy();
        } else {
            SwalBox.close();
            Toaster.error(data.message);
            fetchVacancy();
        }
    }



    const fetchMaterials = async (_courseslug) => {
        toggleLeftMenu();
        const response = await fetch(`${API_BASE}get-learning-materials/${_courseslug}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });
        const data = await response.json();
        if (data.status) {
            var _material_string = '';
            let _materials = data.data;
            //console.info(_materials);
            if (_materials.outer_slides.length > 0) {
                var s_mat = _materials.outer_slides.map(obj => obj.uid);
                _material_string += s_mat.join(',');
            }
            if (_materials.sections.length > 0) {
                _materials.sections.forEach(function (s) {
                    var se_mat = s.slides.map(obj => obj.uid);
                    //console.info(se_mat);
                    if (se_mat.length > 0) {
                        _material_string += ',';
                        _material_string += se_mat.join(',');
                    }
                });
            }
            setMaterialids(_material_string);
        }
    }
    const exportResults = async (download) => {
        SwalBox.fire({
            title: "Processing...",
            html: "Please wait...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              SwalBox.showLoading();
            },
        });
        fetchVacancy(currentPage);
        let _updated_leaderboard_rows = [];
        console.info(employee);
        for (let [i, _existing_leaderboard_row] of employee.entries()) {
            if (_existing_leaderboard_row.system_note === null && _existing_leaderboard_row.duration !== null) {
                if (_existing_leaderboard_row.completed === null) {
                    _existing_leaderboard_row.system_note =  "This candidate has not completed the course.";
                } else if (_existing_leaderboard_row.spent_time_2 < _course_comp_avg) {
                    _existing_leaderboard_row.system_note =  "This candidate has completed the course in less than the average time.";
                } else if (_existing_leaderboard_row.spent_time_2 > _course_comp_avg) {
                    _existing_leaderboard_row.system_note =  "This candidate has spent more than average time & having good quiz score";
                }else{
                    _existing_leaderboard_row.system_note =  "This candidate has spent more than average time & having good quiz score";
                }
            }else{
                //_existing_leaderboard_row.system_note =  "This candidate has not started the course yet.";
                if(_existing_leaderboard_row.spent_time_2 != null && _existing_leaderboard_row.spent_time_2 != 0){
                    _existing_leaderboard_row.system_note =   "This candidate has not completed the course.";
                }else{
                    _existing_leaderboard_row.system_note =   "This candidate has not started the course yet.";
                }
            }
            _updated_leaderboard_rows.push(_existing_leaderboard_row);

        }
        setEmployee([]);
        setEmployee(_updated_leaderboard_rows);


        const emails = employee.map(obj => obj.email);
        var _email_string = emails.join(',');
        var _slug = vacancy_info.course_id;
        let reportscore = [];
        const filteredArray = employee.filter(function (item) {
        var sysNote = item.system_note;
            var cDuration = 'Not Started';
            if (item.duration == null) {
                var cDuration = 'Not Started';
            } else {
                var cDuration = item.duration.total_hours;
            }
            //reportscore.push(item.student_id + '/' + item.email + '/' + item.quiz.right_count + '/' + item.quiz.total_count + '/' + ref_vacancy + '/' + cDuration + '/' + sysNote);
            reportscore.push(item.student_id + '/' + item.email + '/' + item.quiz.right_count + '/' + item.quiz.total_count + '/' + ref_vacancy + '/' + cDuration + '/' + sysNote+ '/' + item.assignment_right_count + '/' + item.assignment_total_count+'/' + item.percentage+'/' + item.assignment_w+ '/' + item.assignment_percentage+ '/' + item.quiz_w+ '/' + item.quiz_percentage);
        });
        try {
            var formData = new FormData();
            formData.append('slug', _slug);
            formData.append('ref_vacancy', ref_vacancy);
            formData.append('slides', btoa(material_ids));
            formData.append('agents', btoa(_email_string));
            formData.append('emails', download);
            formData.append('details', btoa(reportscore.join('*')));
            const response = await fetch(`${LRS_ENDPOINT}report/download-report`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });
            const data = await response.json();

            if (data.status) {

                setReportFileUrl(data.furl);
                if (download === true) {
                    window.location.href = data.furl;
                } else {
                    SwalBox.fire({
                        title: 'Report has been shared.',
                        text: 'An email has been sent to the email address(es) you have provided.',
                        icon: 'success',
                    });
                    setTimeout(() => {
                        document.getElementById('ShareReportClose').click();
                    }, 2000);
                    setTimeout(() => {
                        SwalBox.close();
                    }, 3000);
                }
            }
            SwalBox.close();
        } catch (e) { SwalBox.close(); }
        SwalBox.close();
    }
    const ShareResults = async () => {
        document.getElementById('ShareReportButton').click();
    }

    const clearEmp = () => {
        setNoteContent('')
    }

    const getProctoringData = async (ekCourseSlug, userID) => {
        const formData = new FormData();
        formData.append('course_slug', ekCourseSlug);
        formData.append('user_id', userID);

        const response = await fetch(`${API_BASE}get-all-proctoring`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            // initialAttempt = data.data.attempt_id;
            // console.log(data.data., "=========================")
            // setProctoringSummary(data.data.proctoring);
            let temArray1 = data.data.proctoring;


            if (temArray1 && temArray1.length > 0) {
                let CopyCount = 0;
                let PasteCount = 0;
                let NewTab = 0;
                let Screenshot = 0;
                let Console = 0;
                let Escape = 0;
                let Inactivity = 0;

                temArray1.forEach((obj) => {
                    if (obj.activity_type === "Copy") {
                        CopyCount++;
                    }
                    if (obj.activity_type === "Paste") {
                        PasteCount++;
                    }
                    if (obj.activity_type === "New tab or window") {
                        NewTab++;
                    }
                    if (obj.activity_type === "View Console") {
                        Console++;
                    }
                    if (obj.activity_type === "Inactive") {
                        Inactivity++;
                    }
                    if (obj.activity_type === "Escape") {
                        Escape++;
                    }
                    if (obj.activity_type === "Screenshot") {
                        Screenshot++;
                    }

                });
                setCopyCount2(CopyCount);
                setPasteCount2(PasteCount);
                setNewTab2(NewTab);
                setConsole2(Console);
                setInactivity2(Inactivity);
                setEscape2(Escape);
                setScreenshot2(Screenshot);
            } else {

            }

        } else {
        }
    }

    const openProctoring = (emp) => {
        getProctoringData(ekCourseSlug, emp.student_id);
        document.getElementById('ratingModalCenterclick').click();
    }

    return (
        <>
            <div className="clearfix"></div>
            <a className="btn ml-2 mb-2 d-none" data-toggle="modal" id="ShareReportButton" data-target="#ShareReportModal">&nbsp</a>
            <div className="modal header_red fade" id="ShareReportModal" tabIndex="-1" role="dialog" aria-labelledby="ShareReportModalTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 id="ShareReportTitle">Share Report</h4>
                            <button type="button" id="ShareReportClose" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body invitepop invitepop2">
                            <div className="form-group">
                                <label>Enter Email Addresses to Share Report File</label>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="email_box">
                                    <input
                                        {...register("email", {
                                            required: "required",
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            }
                                        })}
                                        type="text"
                                        placeholder="Enter email address"
                                    />
                                    <button disabled={processing} className="btn btn-circle btn-info ml-2 add_btn">{
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                        {
                                            !processing
                                            &&
                                            "Add"
                                        }</button>
                                </div>
                                <span className="text-danger">
                                    {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                </span>
                            </form>
                            {
                                emails.length > 0
                                &&
                                <>
                                    <div className='emailList_box'>
                                        <ul className="mt-3">
                                            {
                                                emails.map((email, index) =>
                                                    <li key={index} className="border px-2 py-1 mt-1 bg-white">
                                                        <span>{email}</span>
                                                        <i onClick={(e) => { removeItem(e, email) }} className="fa fa-times text-danger float-right mt-1" title="Close" aria-hidden="true"></i>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                            <button onClick={(e) => { processShareReport(); }} disabled={sharingReport} type="button" className="btn btn-circle btn-md submit-pink-btn"> {sharingReport ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Share Report</>}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 materials_section">
                    {
                        <div className="custom-learnig-circle-head">
                            <div className="form-group half_width">
                                <i className="fas fa-search"></i><input onChange={(e) => { fetchVacancy(0, e.target.value); }} type="text" className="form-control" placeholder="Search Students" />
                            </div>
                        </div>

                    }
                </div>
                <div className="col-md-6 materials_section">
                    <button onClick={(e) => { exportResults(true); }} type="button" className="btn btn-circle btn-info"><i className="fa-solid fa-file-excel">&nbsp;</i>Export Results</button>&nbsp;
                    <button onClick={(e) => { ShareResults(); }} type="button" className="btn btn-circle btn-info"><i className="fa-solid fa-share">&nbsp;</i>Share Results</button>
                </div>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Name</th>
                        <th>Time Spent</th>
                        <th>Score Percentage</th>
                        <th>Resume</th>
                        <th>Note</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        employee !== '' && typeof employee !== 'undefined'
                            ?
                            employee.map((emp, index) =>
                                <tr key={index}>
                                    <td className="ek-vac-badge-td"><strong>Rank #{index + 1}</strong>
                                        {index < 3 && emp.duration !== null && emp.duration !== '' &&
                                            <span className="ek-vac-badge badge badge-success" title="This candidate has spent more than average time & having good quiz score.">Recommended</span>
                                        }
                                        {
                                            /*  { index >= 3 && emp.duration !== null && emp.duration !== '' &&
                                                <span className="badge badge-warning" title="This candidate has completed the course in less than the average time.">Not Recommended</span>
                                                } */
                                        }
                                    </td>
                                    <td>
                                        <Link to={`/profile-details/${emp.slug}`} className="secondary-color" target="_blank"><i className="fa-solid fa-user">&nbsp;</i>{emp.full_name}</Link> <VerifiedBadge _is_verified={emp.is_verified} refname="feedcard" /> <br></br>
                                        <a className="" href={`mailto:${emp.email}`}><i className="fa-solid fa-envelope">&nbsp;</i><span title={emp.email}>{emp.email.length > 25 ? emp.email.slice(0, 25) + '...' : emp.email}</span></a>
                                    </td>
                                    <td>
                                        {
                                            emp.duration !== null && emp.duration !== ''
                                                ?
                                                <>{emp?.duration?.total_hours} </>
                                                :
                                                <>Not Completed</>
                                        }
                                    </td>
                                    {/*<td className="openProctoring" onClick={() => { setProctoringData(emp); openProctoring(emp); }} title="Click to open proctoring report">{`${emp?.quiz?.right_count} / ${emp?.quiz?.total_count}`}</td>*/}
                                    {/*<td>{`${emp?.quiz?.right_count} / ${emp?.quiz?.total_count}`}</td>*/ }
                                    <td>{emp.percentage}%</td>
                                    <td className="ek-vac-cv-td">{emp.resume ? <a href={emp.resume} className="secondary-color" target="_blank" download><i className="fa-solid fa-download">&nbsp;</i></a> : '-'}</td>
                                    <td>
                                        <>
                                            <button className="btn btn-circle btn-warning" onClick={() => { setNoteContent(""); setNoteContent(emp); setNoteModalShow(true) }}>Note</button>
                                        </>
                                    </td>
                                    <td>
                                        <select
                                            id={`empva_${emp.student_id}`}
                                            className="form-control"
                                            value={emp.vacancy_status || ""}
                                            onChange={(e) => {
                                                updateStatus(emp.vacancy_status, e.target.value, emp.student_id, index);
                                            }}
                                        >
                                            <option value="">Select status</option>
                                            <option value='Hired'>Hired</option>
                                            <option value='Not Contacted'>Not Contacted</option>
                                            <option value='Rejected'>Rejected</option>
                                        </select>
                                    </td>
                                </tr>
                            )
                            :
                            <tr>
                                <td colSpan={6} align="center">No students found!</td>
                            </tr>
                    }
                </tbody>
            </table>
            <div className="clearfix"></div>
            {
                total_count > 20
                &&
                <div className="custom-pagination">
                    <nav aria-label="Page navigation example">
                        <CustomPagination pageCount={total_count} perPageRecord={20} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                    </nav>
                </div>
            }
            <NoteModal show={NoteModalShow} onHide={() => setNoteModalShow(false)} notecontent={noteContent} needtoRefresh={needtoRefresh} refresh2={refresh2} clearnotecontent={clearnotecontent} _course_comp_avg={_course_comp_avg}/>
            <div className="modal fade ratingpopup" id="ratingModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ratingModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-ek-lg-800" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="ratingModalLongTitle">Proctoring Report</h4>
                            <button type="button" id="ratingModalCenterClose" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="ProctoringFirstPart m-1">
                                <div className="d-flex">
                                    <div className="m-0 col-lg-8 text-left p-1">
                                        <p className="m-0"><span className="font-weight-bold">Name :- </span>{proctoringData.full_name}</p>
                                        <p className="m-0"><span className="font-weight-bold">Email :- </span>{proctoringData.email}</p>
                                        <p className="m-0"><span className="font-weight-bold">Time :- </span>{`${proctoringData?.duration !== null && proctoringData?.duration !== "" ? proctoringData?.duration?.total_hours : "Not Completed"}`}</p>
                                    </div>
                                    <div className="col-lg-4 text-center p-0 mb-0 speedChartdiv">
                                        <p className="font-weight-bold m-0 ProctoringChart">Credit Score {`:- ${proctoringData?.quiz?.right_count}`}</p>
                                        <ReactSpeedometer
                                            segments={3}
                                            needleColor={'#ef403d'}
                                            forceRender={true}
                                            segmentColors={['#EA425C', '#F5CD19', '#5BE12C']}
                                            customSegmentLabels={[
                                                {
                                                    text: "",
                                                },
                                                {
                                                    text: "",
                                                },
                                                {
                                                    text: "",
                                                },
                                            ]}
                                            minValue={0}
                                            maxValue={parseInt(`${proctoringData?.quiz?.total_count !== 0 ? proctoringData?.quiz?.total_count : 10}`)}
                                            value={parseInt(proctoringData?.quiz?.right_count)}
                                            currentValueText={''}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-lg-10 d-flex boxparent">
                                    <div>
                                        <p className="font-weight-bold m-1">Total Score</p>
                                        <div className="square">
                                            <p className="m-0 scoreboxCon">{`${proctoringData?.quiz?.right_count} / ${proctoringData?.quiz?.total_count}`}</p>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="font-weight-bold m-1">No of attempt</p>
                                        <div className="square">
                                            <p className="m-0 scoreboxCon"></p>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="font-weight-bold m-1">Wrong</p>
                                        <div className="square">
                                            <p className="m-0 scoreboxCon"></p>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-2">
                                    {/* <p>Safe</p>
                                    <p>Medium</p>
                                    <p>Suspect</p> */}
                                </div>
                            </div>

                            <div className="">
                                <h5 className="text-left mt-1 mb-1 ml-3 mr-1">Summary</h5>
                                <hr className="ml-3 mr-4 mt-0 mb-0 bg-dark" />
                                <div className="d-flex flex-row mt-2 mr-4 ml-3">
                                    <div className="col-8 m-0 pl-0 pr-0 proctoringReport">
                                        <ProgressBar min={0} max={6} now={CopyCount2} label={`${CopyCount2} ${CopyCount2 !== 0 ? 'times' : ""}`} />
                                    </div>
                                    <div className="col-4 m-0 pl-0 pr-0">
                                        <h6 className="text-right mt-0">Copy</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <hr className="ml-3 mr-4 mt-0 mb-0 bg-dark" />
                                <div className="d-flex flex-row mt-2 mr-4 ml-3">
                                    <div className="col-8 m-0 pl-0 pr-0 proctoringReport">
                                        <ProgressBar min={0} max={6} now={PasteCount2} label={`${PasteCount2} ${PasteCount2 !== 0 ? 'times' : ""}`} />
                                    </div>
                                    <div className="col-4 m-0 pl-0 pr-0">
                                        <h6 className="text-right mt-0">Paste</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <hr className="ml-3 mr-4 mt-0 mb-0 bg-dark" />
                                <div className="d-flex flex-row mt-2 mr-4 ml-3">
                                    <div className="col-8 m-0 pl-0 pr-0 proctoringReport">
                                        <ProgressBar min={0} max={6} now={NewTab2} label={`${NewTab2} ${NewTab2 !== 0 ? 'times' : ""}`} />
                                    </div>
                                    <div className="col-4 m-0 pl-0 pr-0">
                                        <h6 className="text-right mt-0">New tab or window</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <hr className="ml-3 mr-4 mt-0 mb-0 bg-dark" />
                                <div className="d-flex flex-row mt-2 mr-4 ml-3">
                                    <div className="col-8 m-0 pl-0 pr-0 proctoringReport">
                                        <ProgressBar min={0} max={6} now={Screenshot2} label={`${Screenshot2} ${Screenshot2 !== 0 ? 'times' : ""}`} />
                                    </div>
                                    <div className="col-4 m-0 pl-0 pr-0">
                                        <h6 className="text-right mt-0">Take Screenshot</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <hr className="ml-3 mr-4 mt-0 mb-0 bg-dark" />
                                <div className="d-flex flex-row mt-2 mr-4 ml-3">
                                    <div className="col-8 m-0 pl-0 pr-0 proctoringReport">
                                        <ProgressBar min={0} max={6} now={Inactivity2} label={`${Inactivity2} ${Inactivity2 !== 0 ? 'times' : ""}`} />
                                    </div>
                                    <div className="col-4 m-0 pl-0 pr-0">
                                        <h6 className="text-right mt-0">Inactivity</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <hr className="ml-3 mr-4 mt-0 mb-0 bg-dark" />
                                <div className="d-flex flex-row mt-2 mr-4 ml-3">
                                    <div className="col-8 m-0 pl-0 pr-0 proctoringReport">
                                        <ProgressBar min={0} max={6} now={Console2} label={`${Console2} ${Console2 !== 0 ? 'times' : ""}`} />
                                    </div>
                                    <div className="col-4 m-0 pl-0 pr-0">
                                        <h6 className="text-right mt-0">View Console</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <hr className="ml-3 mr-4 mt-0 mb-0 bg-dark" />
                                <div className="d-flex flex-row mt-2 mr-4 ml-3">
                                    <div className="col-8 m-0 pl-0 pr-0 proctoringReport">
                                        <ProgressBar min={0} max={6} now={Escape2} label={`${Escape2} ${Escape2 !== 0 ? 'times' : ""}`} />
                                    </div>
                                    <div className="col-4 m-0 pl-0 pr-0">
                                        <h6 className="text-right mt-0">Escape</h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
            <a className="d-none" data-toggle="modal" data-target="#ratingModalCenter" id="ratingModalCenterclick"></a>
        </>
    )
}
export default VacancyLeaderboard;


const NoteModal = (props) => {

    const [needtoSave, setNeedtoSave] = useState(false);
    const [userNote, setUserNote] = useState('');
    const _token = localStorage.getItem('lmstoken');
    const { vacancy_id } = useParams();
    const [showPlaceholder, setShowPlaceholder] = useState(false);

    useEffect(() => {
        if (props.notecontent.system_note == null) {
            setShowPlaceholder(true);
        }
    }, []);
    const saveNote = async () => {
        if (needtoSave === false) {
            props.onHide();
        } else {
            const formData = new FormData();
            formData.append('student_id', props.notecontent.student_id);
            formData.append('user_note', userNote);
            formData.append('edit_note', true);

            const response = await fetch(`${API_BASE}update-student-vacancy-status/${vacancy_id}`, {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + _token
                },
                body: formData
            });
            const data = await response.json();
            if (data.status) {
                Toaster.success(data.message);
                props.onHide();
                props.needtoRefresh();
            } else {
                Toaster.error(data.message);
                props.onHide();
            }
        }
        props.clearnotecontent();
    }

    return (
        <>
            <Modal
                className="invitePopup header_red"
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <h4>Note</h4>
                    <button type="button" className="close" onClick={() => { props.onHide(); props.clearnotecontent() }}><span aria-hidden="true">×</span></button>
                </Modal.Header>
                <Modal.Body className="scroll_add">
                    <div id="invite_user" className="invitepop">
                        <div className="">
                            <label className="NoteLabel">User Note</label>
                            <textarea className="text-quiz-textarea m-0" name="userNote" rows={4} defaultValue={props.notecontent.user_note}
                                placeholder={props.notecontent.user_note === null ? "You can write your notes here" : ''} onChange={(e) => {
                                    setNeedtoSave(true); setUserNote(e.target.value)
                                }}>
                            </textarea>
                        </div>
                        <div className="">
                            <label className="NoteLabel">System Note</label>
                            <textarea
                                className="text-quiz-textarea m-0 readOnly"
                                name="systemNote"
                                rows={4}
                                defaultValue={props.notecontent.system_note}
                                readOnly={true}
                                title="System note is read only"
                                placeholder={
                                    (() => {
                                    if (props.notecontent.system_note === null && props.notecontent.duration !== null) {
                                            if (props.notecontent.completed === null) {
                                                return "This candidate has not completed the course.";
                                            } else if (props.notecontent.spent_time_2 < props._course_comp_avg) {
                                                return "This candidate has completed the course in less than the average time.";
                                            } else if (props.notecontent.spent_time_2 > props._course_comp_avg) {
                                                return "This candidate has spent more than average time & having good quiz score";
                                            }else{
                                                return "This candidate has spent more than average time & having good quiz score";
                                            }
                                    }else{
                                        if(props.notecontent.spent_time_2 != null && props.notecontent.spent_time_2 != 0){
                                            return "This candidate has not completed the course.";
                                        }else{
                                            return "This candidate has not started the course yet.";
                                        }
                                    }

                                    })()
                                }
                                ></textarea>
                        </div>
                        <div className="">
                            <button type="submit" className="btn btn-circle btn-md submit-pink-btn m-0 mt-1 float-right" onClick={saveNote}>Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};
