import { useContext, useEffect, useState } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { API_BASE } from "../../../constants/global";
import authContext from "../../../context/auth/authContext";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import EduKulaAi from "../../../entity/EdukulaAi";
import Cookies from "js-cookie";
import Joyride from 'react-joyride';

const CourseListing = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const _token = localStorage.getItem('lmstoken');
    const CorpPref = localStorage.getItem('CorpPref');
    const [isTutorServiceEnable, setTutorServiceEnableOrNot] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [processingai, setProcessingAi] = useState(false);
    const [published, setPublished] = useState(0);
    const [drafted, setDrafted] = useState(0);
    const { register, control, formState: { errors }, getValues, setError, clearErrors } = useForm();
    const [tour_steps, setTourSteps] = useState([]);

    const [course_definition, setCourseDefinition] = useState('');
    const [course_duration, setCourseDuration] = useState('');
    const [course_level, setCourseLevel] = useState('');
    const InviteandCreate = localStorage.getItem('InviteandCreate');
    const is_corporate_employee = localStorage.getItem('is_corporate_employee');
    const manage_courses = localStorage.getItem('manage_courses');

    let showComWarn = "";
    if (typeof is_corporate_employee !== 'undefined' && is_corporate_employee !== null && manage_courses === "YES") {
        showComWarn = '<span style="color: #ef403d">Note:- you are creating course as a your company representative and all associated rights are the sole property of your company.</span>';
    }
    useEffect(() => {
        if (CorpPref) {
            generateAicourse();
            localStorage.removeItem('CorpPref');
        }
        else if (InviteandCreate) {
            generateAicourse();
            localStorage.removeItem('InviteandCreate');
        }
        else {
            showCourseTour();
        }
        fetchCounts();
        setTutorServiceEnableOrNot(user?.tutor_service_enabled);
        Cookies.set('ai_course_structure', '');
        Cookies.set('_ai_course_slides', '');
        Cookies.set('ai_course', '');
        Cookies.set('ai_course_info', '');
    }, []);
    const showCourseTour = () => {
        let show_tour = Cookies.get("show_course_tour");  
        if(show_tour != 'NO'){
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Would you like to have a quick tour?',
                text: 'This tour will take you through all the menu items in Corporate Dashbaord section and introduce you with the features.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: 'Yes, please.',
                cancelButtonText:'No, thank you.'
            }).then((result) => {
                if (result.isConfirmed) {
                    startCourseTour();
                }else{
                    Cookies.set('show_course_tour', 'NO');
                }
            });
        }
    }
    const startCourseTour = () => {
        let steps= [];
        if (user?.is_corporate === 1) {
            steps= [
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-course-step-01',title : 'Create course',content: "Click on the \"Create Course\" button to start making a new course. Provide course details, add learning materials, and select the mode of learning. After you finish, submit the course for publishing.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-course-step-02',title : 'Published courses',content: "This tab displays all the courses that you have published on EduKula. You can manage them, update course details, view the enrollment, and invite employees and talent to enrol in your courses.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-course-step-03',title : 'Drafts',content: "This tab shows all the courses that you're currently working on but haven't published yet. You can continue editing them and submit them for publishing whenever you're ready.",},
           ];
        }else{
            steps= [
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-course-step-01',title : 'Create course',content: 'Click on the "Create Course" button to start making a new course. Provide course details, add learning materials, and select the mode of learning. After you finish, submit the course for publishing.',},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-course-step-02',title : 'Published courses',content: 'This tab displays all the courses that you have published on EduKula. You can manage them, update course details, view the enrollment, and invite employees and talent to enrol in your courses.',},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-course-step-03',title : 'Drafts',content: 'This tab shows all the courses that you\'re currently working on but haven\'t published yet. You can continue editing them and submit them for publishing whenever you\'re ready.',},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-course-step-04',title : 'Tutor Enable/Disable toggle button',content: "Use this toggle button to enable or disable your status as a tutor. When enabled, you can create and manage courses, interact with learners, and perform tutoring activities. When disabled, your tutor functionalities will be temporarily deactivated."}
            ];
        }
        setTourSteps(steps);
        Cookies.set('show_course_tour', 'NO');
    }
    
    const fetchCounts = async () => {
        const response = await fetch(`${API_BASE}get-course-counts`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            setPublished(data?.data?.published);
            setDrafted(data?.data?.drafted);
        } else {
            setPublished(0);
            setDrafted(0);
        }
    }
    const IsTutorServiceEnableDisable = async (IsTutorServiceEnable) => {
        var isTutorEnable = user?.is_tutor;
        const formData = new FormData();
        formData.append('is_tutor_service_enable', IsTutorServiceEnable);
        const response = await fetch(`${API_BASE}post-set-tutor/${isTutorEnable}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            setTutorServiceEnableOrNot(IsTutorServiceEnable);
            if (data?.data?.user) {
                localStorage.setItem('lmsuser', JSON.stringify(data.data.user));
                cntxAuth.updateUser(data.data.user);
            }
            setTimeout(() => {
                navigate('/dashboard/tutor/published-courses');
            }, 100);
            Toaster.success(data.message);
        } else {
            Toaster.error(data.message);
        }
    }
    const IsTutorServiceEnableDisablePopup = (e) => {
        var IsTutorServiceEnable = e.target.checked ? 1 : 0;
        var textT = IsTutorServiceEnable ? 'This will enable your tutor services.' : 'This will disable your Tutor services. All the courses with no active learning students enrolled will be unpublished and sent to drafts immediately. Courses having active learning students will be unpublished in 30 days.';
        SwalBox.fire({
            title: 'Are you sure?',
            text: textT,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                IsTutorServiceEnableDisable(IsTutorServiceEnable);
            } else {
                // e.target.checked = _IsTutor;
            }
        });
    }
    const generateAicourse = async () => {
        SwalBox.fire({
            allowOutsideClick: false,
            title: 'Generate a course using AI ',
            // text: 'Would you like to create this course with AI\'s help?',
            html: '<span>Would you like to create this course with AI\'s help? </span>' + `${showComWarn}`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#30314c',
            cancelButtonColor: '#30314c',
            confirmButtonText: 'Yes, please.',
            cancelButtonText:'No, thank you.'
        }).then((result) => {
            if (result.isConfirmed) {
                Cookies.set('ai_course', null);
                Cookies.set('ai_course_info', null);
                Cookies.set('ai_course_structure', null);
                showAiCourseModal();
            }else{
                Cookies.set('ai_course', null);
                Cookies.set('ai_course_info', null);
                Cookies.set('ai_course_structure', null);
                navigate('/dashboard/tutor/create-course');
            }
        });
    }
    const showAiCourseModal = async () => {
        document.getElementById('modalTogglerAiCourse').click();
    }
    const processAicourse = async () => {
        setProcessingAi(true);
        let _ai_course_info                 = {};
        _ai_course_info.course_level        = course_level;
        _ai_course_info.course_structure    = [];

        let _prompt_string  = '';
        let _prompt_def     = '';
        //_prompt_string +="Generate a course structure for a "+course_duration+"-month course to learn "+course_definition+" for "+course_level+ " level students. The course should have a Course Title, Course Description, Course Objective, Prerequisites, Course Structure, and Outcome. Please generate a unique course title. The parameters you need are a brief information about the course, the course's duration, and the learners' level. Please create a weekly course structure based on the given months and provided information.";
        //try {
        _prompt_def += 'You will be given a word or a sentense as an input. Based on that, you will identify the area of subject or topic. And then create a course outline in context of identified topic. '; 
        _prompt_def += 'See the example and get the idea.';
        _prompt_def += 'Input : AWS'; 
        _prompt_def += 'Output :Topic- The course should cover basics of AWS and its services.'; 
        _prompt_def += 'Input : Sales and Marketing'; 
        _prompt_def += 'Output :Topic- The course should cover sales and marketing strategies.'; 
        _prompt_def += 'Here is your word or sentence : '+course_definition;
        
        EduKulaAi.createCompletion({
            model: "text-davinci-003",
            prompt: _prompt_def,
            temperature: 0.1,
            max_tokens: 4097-(_prompt_def.length),
        }).then((completion3) => {
            const responseText3 = completion3.data.choices[0].text;
            let ai_course_definition = responseText3;
            _prompt_string +="Based on course topic, I want you to generate some of the basics details of a course that I am creating for my learners. It should have a Course Title, Course Description, Course Objective, Prerequisites and Outcome. Brief Info : Course or topic to learn "+ai_course_definition+" for "+course_level+ " level students.";
            EduKulaAi.createCompletion({
                model: "text-davinci-003",
                prompt: _prompt_string,
                temperature: 0.1,
                max_tokens: 4097-(_prompt_string.length) ,
            }).then((completion) => {
                const responseText = completion.data.choices[0].text;
                //console.info(responseText); return false;
                Cookies.set('ai_course_info', responseText.trim());
                let added_ai_course = Cookies.get('ai_course_info');
                setProcessingAi(false);


                let _total_weeks = course_duration * 4;
                const _response_text_hedings = added_ai_course.split("\n\n");
                if(_response_text_hedings.length < 1){
                    _response_text_hedings = added_ai_course.split("\n");
                }
                _response_text_hedings.forEach(function(h) { 
                    if(h != ""){
                        let ht = h.trim();
                        let _temp = [];
                        if(ht.includes("Course Title") == true){ 
                            _temp = ht.split(":");
                            _ai_course_info.course_title = _temp[1].trim();
                        }else if(ht.includes("Course Description") == true){ 
                            _temp = ht.split(":");
                            _ai_course_info.course_description = _temp[1].trim();
                        }else if(ht.includes("Course Objective") == true){ 
                            _temp = ht.split(":");
                            _ai_course_info.course_objective = _temp[1].trim();
                        }else if(ht.includes("Prerequisites") == true){ 
                            _temp = ht.split(":");
                            _ai_course_info.course_prerequisites = _temp[1].trim();
                        }else if(ht.includes("Outcome") == true){ 
                            _temp = ht.split(":");
                            _ai_course_info.course_outcome = _temp[1].trim();
                        }else{

                        }
                    }
                });
                let _ai_course_slides    = [];
                if(typeof(_ai_course_info.course_title) != 'undefined' && _ai_course_info.course_title != ""){
                    let _structure_prompt_string    = "";
                    //_structure_prompt_string +="Based on course name and its duration, I want you to generate a Course Structure for "+course_duration+"-month(s) course for "+course_level+ " level students. Please create a weekly course structure based on the given months and level of learners. My course name is "+_ai_course_info.course_title;
                    _structure_prompt_string +="Generate a "+course_duration+"-month(s) course structure for "+course_level+ "-level  students focusing on "+_ai_course_info.course_title+". Develop a  curriculum that aligns with the course's duration and the proficiency level of the learners. The result should have topic nams only nothing else, no any initial message. Do not divide the strucuture on monthly or weekly bases. ";
                    EduKulaAi.createCompletion({
                        model: "text-davinci-003",
                        prompt: _structure_prompt_string,
                        temperature: 0.1,
                        max_tokens: 4097-(_prompt_string.length) ,
                    }).then((completion) => {
                        const st_responseText = completion.data.choices[0].text;
                        console.info(st_responseText);
                        let _st_response_text_hedings = st_responseText.split("\n");
                        _st_response_text_hedings.forEach(function(h) { 
                            let htw = h.trim();
                            /*
                            if(htw != "" && htw != ":" && htw != "."){
                                let _cleaned = htw;
                                if(htw.includes('Week') == true){ 
                                    var _htw = htw.split(":");
                                    _cleaned = _htw[1];
                                }
                                let _t = _cleaned.replace('-','').trim();
                                if(_t != ":" && _t != "." && _t != ""){
                                    var _temp_i = {'title' : _t, 'sections' : []};
                                    _ai_course_slides.push(_temp_i);
                                }
                            }
                            */
                            var _temp_i = {'title' : htw, 'sections' : []};
                            _ai_course_slides.push(_temp_i);

                        });
                        console.info(_ai_course_slides);

                        Cookies.set('_ai_course_slides', []);
                        Cookies.set('_ai_course_slides', JSON.stringify(_ai_course_slides));

                    }).catch((error) => {
                        /*
                        SwalBox.fire({
                            title: 'Something went wrong',
                            text: 'Please try again after some time.',
                            icon: 'error',
                        });
                        */
                        setProcessingAi(false);
                    });
                }else{
                    Cookies.set('_ai_course_slides', []);
                    Cookies.set('_ai_course_slides', JSON.stringify(_ai_course_slides));
                }
                Cookies.set('ai_course_structure', null);
                Cookies.set('ai_course_structure', JSON.stringify(_ai_course_info));
                setProcessingAi(false);
                setTimeout(() => {
                    document.getElementById('aicourseModalClose').click();
                    navigate('/dashboard/tutor/create-course');
                }, 1500);
            }).catch((error) => {
                SwalBox.fire({
                    title: 'Something went wrong',
                    text: 'Please try again after some time.',
                    icon: 'error',
                });
                setProcessingAi(false);
            });
        });


        //}catch(err) { setProcessingAi(false); }


    }
    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        {
                            user.is_corporate === 1
                            ?
                            <>
                                <h4>Corporate Dashboard</h4>
                            </>
                            :
                            <>
                                <h4>Tutor Dashboard</h4>
                                <h6>
                                    Tutor Disable
                                    <label className="switch switch-1-1 tour-course-step-04">
                                        <input type="checkbox" onChange={(e) => { IsTutorServiceEnableDisablePopup(e) }} checked={isTutorServiceEnable === 1 ? 'checked' : ''} name="switch-1-1" id="switch-1-1" />
                                        <span className="slider round slider-1-1"></span>
                                    </label>
                                    Enable
                                </h6>
                            </>
                        }

                        {
                            isTutorServiceEnable === 1
                            ?
                            <Link className="btn btn-circle btn-info tour-course-step-01" to="#" onClick={(e) => { generateAicourse(); }}>Create Course</Link>
                            :
                            null
                        }
                    </div>

                    <div className="learning-circle-courses">
                        {
                            isTutorServiceEnable === 1
                            ?
                            <section>
                                <div className="explore-section">
                                    <div className="container">
                                        <div className="explore-block">
                                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                <li className="nav-item tour-course-step-02">
                                                    <NavLink to={`/dashboard/tutor/published-courses`} className={"nav-link "} id="pills-course-tab" data-toggle="pill" href="#pills-course" role="tab" aria-controls="pills-course" aria-selected="true">Published Courses <span>{published}</span></NavLink>
                                                </li>
                                                <li className="nav-item tour-course-step-03">
                                                    <NavLink to={`/dashboard/tutor/draft-courses`} className={"nav-link "} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Draft <span>{drafted}</span></NavLink>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                <Outlet  context={[published, setPublished, drafted, setDrafted]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            <div className="col-12">
                                <div className="empty_state">
                                    <h3>Tutor service is disabled!</h3>
                                    <img alt="" src="images/no_courses.png" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="materials_section">
            <div className="top_list">
                <div className="modal fade" id="aicourseModal" tabIndex="-1" role="dialog" aria-labelledby="aicourseModalTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header"><h4 id="aicourseModalTitle">Create Course</h4><button type="button" id="aicourseModalClose" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Brief Course Description</label>
                                    <textarea id="course_definition" {...register("course_definition", {
                                    maxLength: {
                                        value: 300,
                                        message: "Brief Course Description can not be more than 300 charaters."
                                    }
                                    })} onChange={(e) => { setCourseDefinition(e.target.value); }} className="form-control" placeholder="Enter brief course description i.e. Basics of Photoshop and Graphic Designing, Digital Marketing Strategies " autoFocus defaultValue={course_definition} />
                                    <span className="add-on max-chr-counter chrchk-course_definition" data-ele="course_definition" data-limit="80" data-showcounter="true"></span>
                                    <span className="text-danger">
                                        {errors.course_definition && errors.course_definition.type === "required" && 'Brief Course Description.'}
                                        {errors.course_definition && errors.course_definition.type !== "required" && errors.course_definition.message}
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label>Learner's Level</label>
                                    <select {...register("course_level")} onChange={(e) => { setCourseLevel(e.target.value); }}>
                                    <option value="">Select Learner's Level </option>
                                        <option value="Introductory">Introductory</option>
                                        <option value="Intermediate">Intermediate</option>
                                        <option value="Expert">Expert</option>
                                    </select>
                                    <span className="text-danger">
                                        {errors.course_level && errors.course_level.type === "required" && 'Please select the section.'}
                                        {errors.course_level && errors.course_level.type !== "required" && errors.course_level.message}
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label>Course Duration</label>
                                    <select {...register("course_duration")} onChange={(e) => { setCourseDuration(e.target.value); }}>
                                        <option value="">Select Course Duration</option>
                                        <option value="1">0-1 Month</option>
                                        <option value="3">1-3 Months</option>
                                        <option value="6">3-6 Months</option>
                                        <option value="9">6-9 Months</option>
                                        <option value="12">9-12 Months</option>
                                    </select>
                                    <span className="text-danger">
                                        {errors.course_duration && errors.course_duration.type === "required" && 'Please select the section.'}
                                        {errors.course_duration && errors.course_duration.type !== "required" && errors.course_duration.message}
                                    </span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                <button onClick={(e) => { processAicourse(); }}  disabled={processingai} type="submit" className="btn btn-circle btn-md submit-pink-btn"> {processingai ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Apply</>} </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a id="modalTogglerAiCourse" data-toggle="modal" data-target="#aicourseModal">&nbsp;</a>
        {
            tour_steps.length > 0 
            &&                 
            <Joyride
                steps={tour_steps}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                showSkipButton={true}
                continuous={true}
                hideCloseButton={true}

                styles={{options: {width: 500,zIndex: 5000,primaryColor: '#31314c',textColor: '#ef4a45',overlayColor: 'rgb(2 1 0 / 69%)',}}}
            />
        } 
        </>
    );
}
export default CourseListing;