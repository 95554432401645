import { useEffect, useState } from "react";
import { Link, NavLink, Outlet,useNavigate } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { CircleICreated, CircleIJoined, PendingInvitation, TrendingCircleI } from './index';
import Joyride from 'react-joyride';
import Cookies from "js-cookie";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { Helmet } from "react-helmet";
const MyCircles = () => {
    const _token = localStorage.getItem('lmstoken');
    const [active_tab, setActiveTab] = useState(1);
    const [pendingInvi_Count, PendingInvitationCount] = useState(0);
    const [tour_steps, setTourSteps] = useState([]);
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const [can_create_circle, setCanCreateCircle] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        fetchGroups();
        fetchMyGroups();
        showCircleTour();
    }, []);
    const showCircleTour = () => {
        let show_tour = Cookies.get("show_circle_tour");  
        if(show_tour != 'NO'){
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Would you like to have a quick tour?',
                text: 'This tour will take you through all the menu items in Learning Circle section and introduce you with the features.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: 'Yes, please.',
                cancelButtonText:'No, thank you.'
            }).then((result) => {
                if (result.isConfirmed) {
                    startCircleTour();
                }else{
                    Cookies.set('show_circle_tour', 'NO');
                }
            });
        }
    }
    const startCircleTour = () => {
        const ek_manage_circles = localStorage.getItem('manage_circles');
        let steps = [];
        if (user?.is_corporate === 1 || ek_manage_circles == 'YES') {
            steps = [
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-learning-circle-step-01',title : 'Create a learning circle',content: "Click here to start a new group. Choose a name, write a description, set the privacy level, and invite participants. Once you've created the circle, you can share educational resources and schedule meetings.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-learning-circle-step-02',title : 'Learning circles I have created',content: "This tab displays all the learning circles you have created. From here, you can manage them, invite members, and close them once their purpose is served. ",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-learning-circle-step-03',title : 'Learning circles I have joined',content: "This tab displays all the learning circles you have joined. You can access them, interact with other members, and contribute to the shared learning materials.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-learning-circle-step-04',title : 'Pending Join Invites',content: "This tab displays all the join invites you have received for learning circles. You can either accept or reject these invites based on your preference.",},

           ];
        }else{
            steps= [
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-learning-circle-step-01',title : 'Create a learning circle',content: "Click here to start a new group. Choose a name, write a description, set the privacy level, and invite participants. Once you've created the circle, you can share educational resources and schedule meetings.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-learning-circle-step-02',title : 'Learning circles I have created',content: "This tab displays all the learning circles you have created. From here, you can manage them, invite members, and close them once their purpose is served.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-learning-circle-step-03',title : 'Learning circles I have joined',content: "This tab displays all the learning circles you have joined. You can access them, interact with other members, and contribute to the shared learning materials.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-learning-circle-step-04',title : 'Pending Join Invites',content: "This tab displays all the join invites you have received for learning circles. You can either accept or reject these invites based on your preference.",},
          ];
        }
        setTourSteps(steps);
        Cookies.set('show_circle_tour', 'NO');
    }
    const fetchGroups = async () => {
        const response = await fetch(`${API_BASE}pending-invitations?page=0`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            PendingInvitationCount(data?.data?.total_pending_count !== undefined ? data?.data?.total_pending_count : 0);

        }
    }
    const fetchMyGroups = async () => {
        const response = await fetch(`${API_BASE}circles-i-have-create?page=0`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            if(data.data.total_count > 0){
                if(parseInt(data.data.total_count) <= 1){
                    setCanCreateCircle(true);
                }else{
                    setCanCreateCircle(false);
                }
            }else{
                setCanCreateCircle(true);
                navigate('/dashboard/my-circles/i-have-joined');
            }
        }
    }



    const checkCircleLimit = async () => {
        let can_create = can_create_circle;
        if (can_create == false) {
            SwalBox.fire({
                allowOutsideClick: false,
                text: "You've reached maximum number of Learning Circle you can create. In order to create a new learning circle you'll either be required to leave or close a circle earlier created.",
                icon: 'info',
                showCancelButton: false,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: 'OK'
            });
        } else {
            const ek_manage_circles = localStorage.getItem('manage_circles');
            if (ek_manage_circles == 'YES') {
                SwalBox.fire({
                    allowOutsideClick: false,
                    text: "Note:- you are creating learning circle on behalf of your company and all associated rights are the sole property of your company.",
                    // text: t('_mylc_managelc_warn_m1_'),
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#30314c',
                    cancelButtonColor: '#30314c',
                    confirmButtonText: 'OK'
                    // confirmButtonText: t('_mylc_ok_')
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/dashboard/create-learning-circle');
                    }
                });
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - My Learning Circles'}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content">
                    <div className="custom-learnig-circle-head">
                        <h4>My Learning Circles</h4>
                        <button  onClick={(e) => { checkCircleLimit() }} className="btn btn-circle btn-info tour-learning-circle-step-01" type="button" data-toggle="modal">Create a Learning Circle</button>
                    </div>

                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block">
                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                            <li className="nav-item tour-learning-circle-step-02">
                                                <NavLink to={`/dashboard/my-circles/i-have-created`} className={`nav-link`} id="pills-course-tab" data-toggle="pill" href="#pills-course" role="tab" aria-controls="pills-course" aria-selected="true">Circles I Have Created</NavLink>
                                            </li>
                                            <li className="nav-item tour-learning-circle-step-03">
                                                <NavLink to={`/dashboard/my-circles/i-have-joined`} className={`nav-link`} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Circles I Have Joined</NavLink>
                                            </li>
                                            <li className="nav-item tour-learning-circle-step-04">
                                                <NavLink to={`/dashboard/my-circles/invitation`} className={`nav-link`} id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Pending Join Invitations <span className="join-notification">{pendingInvi_Count}</span></NavLink>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <Outlet context={[PendingInvitationCount]} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <TrendingCircleI />
            </div>


            {
            tour_steps.length > 0 
            &&                 
            <Joyride
                steps={tour_steps}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                showSkipButton={true}
                continuous={true}
                hideCloseButton={true}

                styles={{options: {width: 500,zIndex: 5000,primaryColor: '#31314c',textColor: '#ef4a45',overlayColor: 'rgb(2 1 0 / 69%)',}}}
            />
            }  




        </>
    );
}

export default MyCircles;