import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE } from "../../../../constants/global";
import { Toaster } from "../../../../entity/GlobalJS";
import RateStart from "../../../../components/RateStart";


const Step5 = (props) => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const course_id = props.cid;
    const [profile_errors, setProfileError] = useState('');
    const _token = localStorage.getItem('lmstoken');
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const rateArrays = [1, 2, 3, 4, 5];
    const [my_rating, setMyRating] = useState(0);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const ek_manage_courses = localStorage.getItem('manage_courses');
    const is_corporate_employee = localStorage.getItem('is_corporate_employee');
    const manage_courses = localStorage.getItem('manage_courses');
    const saveStepFive = async () => {
        var sURL = `${API_BASE}post-create-course-stepFive/${course_id}`;
        var formData = new FormData();
        if (user?.is_corporate === 1) {
            formData.append('owner_type', 'corporate');
        }
        // else if (user?.is_corporate !== 1 || ek_manage_courses == 'YES') {
        //     formData.append('owner_type', 'corporate');
        // }
        else if (user?.is_corporate !== 1 && typeof is_corporate_employee !== 'undefined' && is_corporate_employee !== null && manage_courses === "YES") {
            formData.append('owner_type', 'corporate');
        }

        else if (user?.is_corporate !== 1) {
            formData.append('owner_type', 'self');
        }
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        setSubmitting(true);
        const data = await response.json();
        if (data.status) {
            setProfileError('');
            Toaster.success(data.message);
            navigate('/dashboard/tutor/draft-courses', {
                state: {
                    is_from: 'in_review',
                    course_name: data.data.course_name,
                    course_slug: data.data.course_slug,
                    associated_vacancy: (typeof data?.data?.associated_vacancy !== 'undefined' && data?.data?.associated_vacancy !== '') ? data?.data?.associated_vacancy : '',
                    cou_id: course_id
                }
            });
            setSubmitting(false);
        } else {
            if (data.data.not_tutor) {
                setProfileError(data.message);
            }

            Toaster.error(data.message);
            setSubmitting(false);
        }
    }


    useEffect(() => {
        localStorage.removeItem('DCStep');
        localStorage.removeItem('cid');
    }, []);

    const postRate = async (rate) => {
        setMyRating(rate);
    }

    async function sendExperienceData() {
        let formData = {};

        const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
        const _lms_learner_id = _lmsuser['id'];
        formData.user_id = _lms_learner_id;
        formData.course_id = course_id;
        formData.message = feedbackMessage;
        formData.rating = my_rating; // 1 to 5
        formData.feedback_type = 'tutor'; // tutor or student

        const response = await fetch(API_BASE + 'course/feedback', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(formData)
        });

        const data = await response.json();
        if (data.status) {
            document.getElementById('ratingModalCenterClose').click();
            Toaster.success(data.message);
            saveStepFive();
        } else {
            document.getElementById('ratingModalCenterClose').click();
            saveStepFive();
        }
    }

    const handleratingModalClose = () => {
        saveStepFive();
    }
    return (
        <>
            <h4>Submit for Review</h4>
            <div className="modeof_section">
                <p className="mb-3">Your course will be submitted for the review. Once the course is review and approved then after your course will be published on the platform. </p>
                {
                    profile_errors !== ''
                    &&
                    <div className="card mb-3 card-step5-tutor">
                        <div className="col-md-12 col-lg-12">
                            <div className="edit_prof_msg">
                                <p>{user.is_corporate ? `In order to submit a course for review, your company’s general profile must be completed with the company’s introduction.` : `In order to submit a course for review, your profile must be completed with your name, self-introduction, a photo, and tutor preferences`}</p>
                                <Link className="btn" to={'/profile/general'} onClick={() => { localStorage.setItem('DCStep', true); localStorage.setItem('cid', course_id); localStorage.setItem('showModalGeneral', true) }}>Edit Profile</Link>
                            </div>
                        </div>
                        <div className="blog-card-inner">
                            <ul>
                                {
                                    profile_errors.map((error, index) =>
                                        <li key={index} className="alert alert-warning" role="alert">{error}</li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                }
                <button disabled={submitting} type="button" onClick={(e) => { props.setStep(4); }} className="btn btn-circle btn-md cancel-btn">Previous</button>
                <button disabled={submitting} onClick={(e) => {
                    document.getElementById('ratingModalCenterclick').click();
                    // setTimeout(() => {
                    //     saveStepFive();
                    // }, 3000);

                }} className="btn btn-circle btn-md submit-pink-btn">{submitting ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Submit'}</button>
                <div className="clearfix"></div>
            </div>

            <div className="modal fade ratingpopup" id="ratingModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ratingModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="ratingModalLongTitle">How was your experience ?</h4>
                            <button type="button" id="ratingModalCenterClose" className="close" data-dismiss="modal" aria-label="Close" 
                            // eslint-disable-next-line
                            onClick={() => { handleratingModalClose }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pt-2 pb-2">
                            <h5 className="m-0">Rate us</h5>
                            {
                                rateArrays.map((rate_val, indexv) =>
                                    <RateStart key={indexv} postRate={postRate} value={rate_val} my_rating={my_rating} />
                                )
                            }
                            <div className="form-group mt-2 mb-0">
                                <textarea name="feedbackMessage" placeholder="Please Share your experience here..." className="form-control" onChange={(e) => setFeedbackMessage(e.target.value)} rows={3}></textarea>
                            </div>
                            <div className="float-right mr-0 mt-3 mb-2">
                                <button className="btn btn-success btn-circle btn-md" onClick={sendExperienceData}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a className="d-none" data-toggle="modal" data-target="#ratingModalCenter" id="ratingModalCenterclick">View Slide</a>
        </>
    );
}

export default Step5;
